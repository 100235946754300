export class Department {
    public static values = [
        "Data Analytics",
        "State Eval and RR",
        "Evaluation",
        "Triage & EWS",
        "Strategic Communications",
        "CMR",
        "HOP/MortSci Evaluators & Sanitarians",
        "LCR",
        "Engineering",
        "Records Mgmt & Internal Communications",
        "Call Center",
        "SLT",
        "BodyArt",
        "State Fire Marshall",
        "Evaluators",
        "Outreach & Engagement",
        "Budget",
        "Recon & Policy",
        "Admin Specialist",
        "MortSci",
        "Enforcement",
        "Triage",
        "Intake",
        "NAR",
        "HRD",
        "Case Mix",
        "Admin Generalist"
    ];
}