<template>
  <div class="home">
    <h2>Project Exiting Information</h2>

    <!--
    <v-row dense class="mt-3">
      <v-col cols="2">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="District ID"
            type="input"
            v-model="evaluation.district"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="District Name"
            type="input"
            v-model="evaluation.districtName"
        />
      </v-col>
      <v-col cols="6"/>
    </v-row>
    -->

    <v-row dense>
      <v-col cols="2">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="County ID"
            type="input"
            v-model="evaluation.providerCountyId"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="County Name"
            type="input"
            v-model="evaluation.providerCounty"
        />
      </v-col>
      <v-col cols="6"/>
    </v-row>

    <v-row dense>
      <v-col cols="3">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="Project Number"
            type="input"
            v-model="evaluation.evaluationNumber"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="Facility Name"
            type="input"
            v-model="evaluation.providerName"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="2">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="Survey Type ID"
            type="input"
            v-model="evaluation.surveyType"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            rounded
            disabled
            class="text-black"
            label="Survey Type"
            type="input"
            v-model="evaluation.surveyTypeName"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="4">
        <v-text-field
            outlined
            label="Date Entered"
            type="date"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            label="Date Exited"
            type="date"
        />
      </v-col>
      <v-col cols="4"/>
    </v-row>

    <v-row dense>
      <v-col cols="2">
        <v-text-field
            outlined
            label="Fiscal Year"
            type="number"
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
            label="Enforcement?"
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
            label="Licensing Action?"
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
            label="Maltreatment?"
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
            label="Aborted?"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="4">
        <v-radio-group row label="Initial: ">
          <v-radio label="Yes" value="y"/>
          <v-radio label="No" value="n"/>
        </v-radio-group>
      </v-col>
      <v-col cols="4">
        <v-radio-group row label="CHOW: " >
          <v-radio label="Yes" value="y"></v-radio>
          <v-radio label="No" value="n"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="4"/>
    </v-row>

    <v-row dense>
      <v-col cols="4">
        <v-text-field
            outlined
            :maxlength="1"
            label="Type of Evaluation"
            type="text"
        />
      </v-col>
      <!--
      <v-col cols="4">
        <v-text-field
            outlined
            :maxlength="1"
            label="Extent of Evaluation"
            type="text"
        />
      </v-col>
      -->
    </v-row>



    <v-row dense>
      <v-col cols="3">
        <v-text-field
            outlined
            label="Correction Orders"
            type="number"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
            outlined
            label="Scope and Level Code"
            :maxLength="1"
            type="text"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
            outlined
            label="Highest Scope"
            type="number"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
            outlined
            label="Highest Level"
            type="number"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-radio-group row label="Schedule a Follow-up?: " style="font-size: 20px">
          <v-radio label="Yes" value="y"/>
          <v-radio label="No" value="n"/>
          <v-radio label="Unknown" value="u"/>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense class="mt-5">
      <v-col cols="4">
        <v-checkbox
            label="Request Informal Conference"
        />
        <v-checkbox
            label="Immediate Fines"
        />
        <v-checkbox
            label="Immediate Correction Orders"
        />
        <v-checkbox
            label="License Revocation"
        />
      </v-col>
      <v-col cols="4">
        <v-checkbox
            label="Request Plan of Corrections"
        />
        <v-checkbox
            label="License Denied"
        />
        <v-checkbox
            label="License Granted"
        />
        <v-checkbox
            label="Performance Incentive Eligible"
        />
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="8">
        <v-textarea outlined label="Comment" rows="5"></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script >

export default {

  //TODO:Make desired fields read-only

  props: {
    evaluation: Object
  },

  mounted () {
    window.scrollTo(0, 0)
  }


}
</script>

<style>

.v-input--radio-group legend.v-label {
  font-size: 18px;
  font-weight: bold;
}

.v-input__control {
  padding-top: 0px;
  padding-bottom: 0px;
}

.text-black input {
  /*background-color: gray !important;*/
  color: black !important;
}

</style>